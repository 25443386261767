import React from 'react';

// === Components === //
import { Redirect } from '@reach/router';
import Page from '@components/layout/Page';
import Container from '@components/common/Container';
import HeroBanner from '@components/sections/HeroBanner';
import CountriesList from '@components/sections/CountriesList';

// === Content === //
import { languagesPageData } from '@graphql/LanguagesPage';

// === Helpers === //
import * as utils from '@utils';

// === Styles === //
import styled from '@emotion/styled';
import { colors, dimensions, fluidRange } from '@styles';

// === Types === //
import { PageType, Variant } from '@content/types/layout';
import { LanguagesPageData } from '@content/types/languagesPage';

const PageBodyWrapper = styled.div`
  p {
    font-size: ${dimensions.fontSize.regular}px;
    line-height: 20px;
    margin-bottom: 20px;
    color: ${colors.text.light};
  }

  h1,
  h2,
  h3,
  h4 {
    ${fluidRange('marginTop', '48px', '60px')}
    ${fluidRange('marginBottom', '20px', '30px')}
  }
`;

const WorkInProgressText = styled.div`
  color: ${colors.ui.hcpDefault};
  font-weight: 700;
`;

interface LanguagesPageProps {
  pageContext?: LanguagesPageContextData;
}

interface LanguagesPageContextData {
  slug: string;
  uuid: string;
  variant: Variant;
}

const LanguagesPage = ({ pageContext }: LanguagesPageProps) => {
  const pageType: PageType = 'languages_page';
  const pages: LanguagesPageData[] = languagesPageData();
  const page: LanguagesPageData | undefined =
    pages.find((page) => page.uuid === pageContext?.uuid) || undefined;

  return page ? (
    <Page
      variant={pageContext?.variant || 'blue'}
      pageType={pageType}
      navUuid={page.uuid}
      bottomSpace={true}
      metaData={page.metaData}
      pageUrl={`/${pageContext?.slug || ''}`}
    >
      <>
        <HeroBanner
          image={page.heroBanner.image.src}
          image_mobile={page.heroBanner.imageMobile.src}
          text={page.heroBanner.text}
          size="small"
        />
        <Container>
          <PageBodyWrapper>
            <CountriesList countries={page.countries} variant={pageContext?.variant || 'blue'} />

            {page.workInProgressText && page.workInProgressText !== '' && (
              <WorkInProgressText>{utils.SafeHtml(page.workInProgressText)}</WorkInProgressText>
            )}
          </PageBodyWrapper>
        </Container>
      </>
    </Page>
  ) : (
    <Redirect to="/404" />
  );
};

export default LanguagesPage;
