import { ICountriesListItem } from '@content/types/languagesPage';
import React from 'react';

// === Components === //
import { Link } from 'gatsby';

// === Styles === //
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { animation, breakpoints, colors, respondFrom } from '@styles';

// === Types === //
import { Variant } from '@content/types/layout';

const Image = styled.img`
  max-width: 100%;
`;

const CustomLink = styled(Link)<ICustomLink>`
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${colors.text.light};
  transition: color ease-in-out ${animation.fast}ms;

  &:hover {
    color: ${(props) =>
      props.variant === 'blue' ? colors.ui.patientDefault : colors.ui.hcpDefault};
  }
`;

const CustomLinkExternal = styled.a<ICustomLink>`
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${colors.text.light};
  transition: color ease-in-out ${animation.fast}ms;

  &:hover {
    ${(props) =>
      respondFrom(
        breakpoints.lg,
        css`
          color: ${props.variant === 'blue' ? colors.ui.patientDefault : colors.ui.hcpDefault};
        `
      )}
  }
`;

const Title = styled.span``;

interface ICountryLink extends ICountriesListItem {
  variant: Variant;
}

interface ICustomLink {
  variant: Variant;
}

const CountryLink = ({ title, url, icon, variant }: ICountryLink) => {
  return (
    <div>
      {url.includes('://') ? (
        <CustomLinkExternal href={url} variant={variant}>
          {icon && (
            <span>
              <Image src={icon.src} alt={icon.alt} title={icon.title} height="34px" width="34px" />
            </span>
          )}
          <Title>{title}</Title>
        </CustomLinkExternal>
      ) : (
        <CustomLink to={url} variant={variant}>
          {icon && (
            <span>
              <Image src={icon.src} alt={icon.alt} title={icon.title} height="34px" width="34px" />
            </span>
          )}
          <Title>{title}</Title>
        </CustomLink>
      )}
    </div>
  );
};

export default CountryLink;
