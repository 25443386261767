import React from 'react';

// === Components === //
import Container from '@components/common/Container';

// === Helpers === //
import * as utils from '@utils';

// === Styles === //
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoints, colors, respondTo } from '@styles';

const BannerWrapper = styled.div<BannerWrapperProps>`
  width: 100vw;
  height: ${(props) => (props.size === 'small' ? '19vw' : '34.41vw')};
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  ${(props) =>
    respondTo(
      breakpoints.md,
      css`
        background-image: url(${props.image_mobile});
        height: 60.6vw;
      `
    )}
`;

const TextBox = styled.div`
  width: 70%;
  position: absolute;
  top: 178px;
  ${respondTo(
    breakpoints.md,
    css`
      top: 131px;
    `
  )}
`;

const TextContent = styled.h1`
  color: ${colors.white};
  width: 100%;
  /* text-shadow: 0px 6px 15px rgba(0, 0, 0, 0.686); */

  span {
    display: block;
  }
`;

interface BannerWrapperProps {
  image: string;
  image_mobile: string;
  size: string;
}

interface HeroBannerProps {
  text: string;
  image: string;
  image_mobile: string;
  size?: string;
}

const HeroBanner = ({ text, image, image_mobile, size = 'default' }: HeroBannerProps) => {
  return (
    <BannerWrapper image={image} image_mobile={image_mobile} size={size}>
      <Container>
        <TextBox>
          <TextContent>{utils.SafeHtml(text)}</TextContent>
        </TextBox>
      </Container>
    </BannerWrapper>
  );
};

export default HeroBanner;
