import React from 'react';

// === Components === //
import CountryLink from '@components/common/CountryLink';

// === Styles === //
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoints, respondFrom } from '@styles';

// === Types === //
import { ICountriesListItems } from '@content/types/languagesPage';
import { Variant } from '@content/types/layout';

const Wrapper = styled.div`
  padding: 0;

  ${respondFrom(
    breakpoints.lg,
    css`
      padding: 100px 0 0;
    `
  )};
`;

const CountriesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 26px;
  margin: 50px 0;

  ${respondFrom(
    breakpoints.md,
    css`
      grid-template-columns: repeat(2, 1fr);
    `
  )};

  ${respondFrom(
    breakpoints.lg,
    css`
      grid-template-columns: repeat(3, 1fr);
    `
  )};
`;

interface ICountriesList {
  countries: ICountriesListItems;
  variant: Variant;
}

const CountriesList = ({ countries, variant }: ICountriesList) => {
  const compare = (a, b) => {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  };

  return (
    <Wrapper>
      <CountriesWrapper>
        <CountryLink {...countries.international} variant={variant} />
      </CountriesWrapper>
      <CountriesWrapper>
        {countries.all.map((country) => {
          const data = country;

          if (!country.url.includes('://')) {
            data.url = `${variant === 'red' ? '/hcp' : ''}${country.url}`;
          }

          return <CountryLink key={country.title} variant={variant} {...data} />;
        })}
      </CountriesWrapper>
    </Wrapper>
  );
};

export default CountriesList;
